import React from 'react';
import axios from 'axios';
import { NSContext, NSToast, jwtManager } from 'aqlrc';
import { Router } from '../routes';
import getAPIUrl from '../lib/getAPIUrl';
import { withI18next } from '../lib/withI18n';

class PricingTables extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            errorExistStore: false,
            shopName: '',
            step: 0,
            type: 'free'
        };
    }

    handleGetStarted = async (e, type) => {
        e.preventDefault();
        const { routerLang, urlLang } = this.context.props;
        const jwt = jwtManager.get();

        // Si pas connecté => page login
        const asPath = Router.asPath;
        if (!jwt) {
            return Router.pushRoute('auth', { lang: routerLang, return: asPath })
        }

        const res = await axios.get(`${getAPIUrl()}v2/addon-saas-aquila/user/shop`);
        if (res.data.datas.length) {
            return Router.pushRoute('shop', { lang: routerLang })
        }
        this.setState({step: 1, type});
    }

    handleShopName = (e) => {
        this.setState({ shopName: e.target.value })
    }

    onSubmitShop = async (e) => {
        e.preventDefault();
        const { shopName, type } = this.state;
        const { routerLang } = this.context.props;
        const jwt = jwtManager.get();

        this.setState({ errorExistStore: false })
        if (!shopName.match(/^[a-z0-9-]{3,}$/i)) {
            return NSToast.error('pricing-tables:errors.invalidNameStore');
        }

        // Si pas connecté => page login
        if (!jwt) {
            return Router.pushRoute('auth', { lang: routerLang })
        }

        const res = await axios.post(`${getAPIUrl()}v2/addon-saas-aquila/exists`, { name: shopName});
        if (res.data) {
            return this.setState({ errorExistStore: true })
        }

        try {
            await axios.put(`${getAPIUrl()}v2/addon-saas-aquila/shop`, { name: shopName, active: false });
            Router.pushRoute('shop', { lang: routerLang })
        } catch (e) {
            NSToast.error('pricing-tables:errors.createStore');
        }
    }

    render() {
        const { t } = this.props;
        const { errorExistStore, shopName, step } = this.state;
        return (
            <>
                {
                    step === 0 && (
                        <>
                            <h2>{t('pricing-tables:title1')}</h2>
                            <div className="pricing-tables">
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="table-default table6 red">
                                                <div className="table__header">
                                                    <img className="table__header--icon" src="/medias/basic.png" alt="Basic Icon" />
                                                    <h3 className="table__header--title">Community</h3>
                                                    <p className="table__header--price">
                                                        {t('pricing-tables:free')}
                                                    </p>
                                                </div>
                                                <div className="table__content">
                                                    <ul className="table__content--list">
                                                        <li>{t('pricing-tables:table.community.point1')}</li>
                                                        <li>{t('pricing-tables:table.community.point2')}</li>
                                                        <li>{t('pricing-tables:table.community.point3')}</li>
                                                        <li>{t('pricing-tables:table.community.point4')}</li>
                                                        <li>{t('pricing-tables:table.community.point5')}</li>
                                                        <li>{t('pricing-tables:table.community.point6')}</li>
                                                    </ul>
                                                </div>
                                                <div className="table__footer">
                                                    <button type="button" className="btn btn--color color-purple" onClick={(e) => this.handleGetStarted(e, 'free')}>
                                                        <span className="btn__content">{t('pricing-tables:btn.getStarted')}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="table-default table6 orange">
                                                <div className="table__header">
                                                    <img className="table__header--icon" src="/medias/premium.png" alt="Premium Icon" />
                                                    <h3 className="table__header--title">Professional</h3>
                                                    <p className="table__header--price">
                                                        {t('pricing-tables:comingSoon')}
                                                    </p>
                                                </div>
                                                <div className="table__content">
                                                    <ul className="table__content--list">
                                                        <li>&nbsp;</li>
                                                        <li>&nbsp;</li>
                                                        <li>&nbsp;</li>
                                                        <li>&nbsp;</li>
                                                        <li>&nbsp;</li>
                                                        <li>&nbsp;</li>
                                                    </ul>
                                                </div>
                                                <div className="table__footer">
                                                    <button type="button" disabled={true} className="btn btn--color color-purple" onClick={(e) => this.handleGetStarted(e, 'premium')}>
                                                        <span className="btn__content">{t('pricing-tables:btn.getStarted')}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
                {
                    step === 1 && (
                        <>
                            <h2>{t('pricing-tables:title2')}</h2>
                            <form onSubmit={this.onSubmitShop}>
                                <div className="account-informations">
                                    <input type="text" value={shopName} className="field" onChange={this.handleShopName} />
                                </div>
                                <div className="pricing-tables-actions">
                                    <button type="button" className="btn btn--color color-gray" onClick={() => this.setState({ step: 0 })}>
                                        <span className="btn__content">{t('pricing-tables:btn.return')}</span>
                                    </button>
                                    <button type="submit" className="btn btn--color color-orange">
                                        <span className="btn__content">{t('pricing-tables:btn.create')}</span>
                                    </button>
                                    {
                                        errorExistStore && (
                                            <span className="error-exist-store">
                                                {t('pricing-tables:errors.existStore')}
                                            </span>
                                        )
                                    }
                                </div>
                            </form>
                        </>
                    )
                }
            </>
        );
    }

    static contextType = NSContext;
}

export default withI18next(['pricing-tables'])(PricingTables);
